import type { AirlineJSON } from '../Airline'
import { Model } from '../Model'
import type { FlightAirportJSON } from './Airport'
import type { RateJSON } from './Rate'

interface FlightBase {
  id: number
  auto_name: string
  comment: string | null
  airline: AirlineJSON
  departure_airport: FlightAirportJSON
  stopover_airport: FlightAirportJSON
  arrival_airport: FlightAirportJSON
  rates: RateJSON[]
  disabled: boolean
  current_update_user_id: number | null
  last_update_user_id: number | null
}

export interface FlightJSON extends FlightBase {
  current_update_dt: string | null
  last_update_dt: string | null
}

export interface Flight extends FlightBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
}

export class Flight extends Model<FlightJSON> {
  constructor(data: FlightJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
  }
}
