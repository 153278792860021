import { authenticate } from '../lib/decorators'
import type { ApiRequestDescriptor } from '../lib/request'
import { get } from '../lib/methods'
import type { RecipientType } from '../models/RecipientType'

export interface AutocompleteResult {
  id: number
  description: string
}

export interface AutocompleteAirportResult extends AutocompleteResult {
  name: string
  iata: string
  country_id: number
  country_name: string
  country_code: string
  city: string
}

export interface AutocompleteAirlineResult extends AutocompleteResult {
  name: string
  iata: string
}

export interface AutocompleteCityhallResult extends AutocompleteResult {
  name: string
  insee_code: string
  postal_code: string
}

export interface AutocompleteCountriesResult extends AutocompleteResult {
  name: string
  country_code: string
}

export interface AutocompleteDealEntitiesResult extends AutocompleteResult {
  entity_id: number
  entity_type: RecipientType
}

export interface AutocompleteDealWithInvoiceResult extends AutocompleteResult {
  uuid: string
}

export interface AutocompleteDepartmentsResult extends AutocompleteResult {
  department_code: string
}

export interface AutocompletePlaceResult extends AutocompleteResult {
  postal_code: string
  address: string
  name?: string
  city?: string
  insee_code?: string
}

export interface AutocompleteFlightsResult extends AutocompleteResult {
  arrival_city: string
  arrival_country: string
  arrival_country_code: string
}

export interface AutocompleteSuppliersResult extends AutocompleteResult {
  name: string
  short_name: string | null
}

export const Autocompletes = {
  Path: {
    airlines: '/api/v1/autocompletes/airlines',
    airports: {
      index: '/api/v1/autocompletes/airports',
      warehouses: (id: number): string => `/api/v1/autocompletes/airports/${id}/warehouses`,
    },
    companies: '/api/v1/autocompletes/companies',
    consulates: '/api/v1/autocompletes/consulates',
    countries: '/api/v1/autocompletes/countries',
    cityhalls: '/api/v1/autocompletes/cityhalls',
    crematoriums: '/api/v1/autocompletes/crematoriums',
    deals: {
      documents: (dealUuid: string): string => `/api/v1/autocompletes/deals/${dealUuid}/documents`,
      entities: (dealUuid: string): string => `/api/v1/deals/${dealUuid}/entities`,
      withInvoices: '/api/v1/autocompletes/deals_with_invoices',
    },
    defuncts: '/api/v1/autocompletes/defuncts',
    departments: '/api/v1/autocompletes/departments',
    documentTypes: '/api/v1/autocompletes/document_types',
    entityInvoices: '/api/v1/autocompletes/entity_invoices',
    flights: '/api/v1/autocompletes/flights',
    funeralParlors: '/api/v1/autocompletes/funeral_parlors',
    funeralWorkTypes: '/api/v1/autocompletes/funeral_work_types',
    graveyards: '/api/v1/autocompletes/graveyards',
    hospitals: '/api/v1/autocompletes/hospitals',
    police: '/api/v1/autocompletes/polices',
    prefectures: '/api/v1/autocompletes/prefectures',
    products: '/api/v1/autocompletes/products',
    rha: '/api/v1/autocompletes/rha',
    ruleSets: '/api/v1/autocompletes/rule_sets',
    suppliers: '/api/v1/autocompletes/suppliers',
    worships: '/api/v1/autocompletes/worships',
  },

  airlines: (q?: string): ApiRequestDescriptor<AutocompleteAirlineResult[]> =>
    authenticate(get(Autocompletes.Path.airlines, { q })),

  airports: (q?: string): ApiRequestDescriptor<AutocompleteAirportResult[]> =>
    authenticate(get(Autocompletes.Path.airports.index, { q })),

  cityhalls: (q?: string): ApiRequestDescriptor<AutocompleteCityhallResult[]> =>
    get(Autocompletes.Path.cityhalls, { q }),

  companies: (q?: string): ApiRequestDescriptor<AutocompleteResult> =>
    authenticate(get(Autocompletes.Path.companies, { q })),

  countries: (
    q?: string,
    withFlightEstimation?: boolean,
  ): ApiRequestDescriptor<AutocompleteCountriesResult[]> =>
    get(Autocompletes.Path.countries, {
      q,
      flight_estimation: withFlightEstimation,
    }),

  crematoriums: (q?: string): ApiRequestDescriptor<AutocompletePlaceResult> =>
    get(Autocompletes.Path.crematoriums, { q }),

  defuncts: (q?: string): ApiRequestDescriptor<AutocompleteResult> =>
    authenticate(get(Autocompletes.Path.defuncts, { q })),

  departments: (q?: string): ApiRequestDescriptor<AutocompleteDepartmentsResult[]> =>
    authenticate(get(Autocompletes.Path.departments, { q })),

  flights: (q?: string): ApiRequestDescriptor<AutocompleteFlightsResult[]> =>
    get(Autocompletes.Path.flights, { q }),

  funeralParlors: (q?: string): ApiRequestDescriptor<AutocompletePlaceResult> =>
    get(Autocompletes.Path.funeralParlors, { q }),

  graveyards: (q?: string): ApiRequestDescriptor<AutocompletePlaceResult> =>
    get(Autocompletes.Path.graveyards, { q }),

  hospitals: (q?: string): ApiRequestDescriptor<AutocompletePlaceResult> =>
    get(Autocompletes.Path.hospitals, { q }),

  worships: (q?: string, tag?: string): ApiRequestDescriptor<AutocompletePlaceResult> => {
    let path = Autocompletes.Path.worships
    if (tag) {
      path += `/${tag}`
    }
    return get(path, { q })
  },
}
