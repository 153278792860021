import { createSelector, Selector } from 'reselect';

import type { LegacyStep } from 'models/Deal/Step';

import { DEAL_TYPE_MARBLE } from '../../constants';
import { makeSelectSteps } from '../../selectors.js';
import type { AppStateSubset } from '../../slice';
import {
  STEP_URN_SEALING,
  STEP_INTERMENT,
  STEP_TAC,
  STEP_CLOSING,
  STEP_CASKETING,
} from '../../DealFuneral/constants.js';

function getCasketingPostalCodes(steps: LegacyStep[], index: number): string[] {
  let cursor = index;
  const postalCodes: string[] = [];

  const casketing = steps[index];
  if (casketing?.location?.address?.postal_code) {
    postalCodes.push(casketing.location.address.postal_code);
  }

  if ((steps[index + 1].eventType as string) !== STEP_CLOSING) {
    return postalCodes;
  }

  cursor += 2;
  while (
    (steps[cursor].eventType as string) === STEP_TAC &&
    cursor < steps.length
  ) {
    cursor += 1;
  }

  if (cursor >= steps.length) {
    return postalCodes;
  }

  const postalCode = steps[cursor].location?.address?.postal_code;
  if (postalCode && !postalCodes.includes(postalCode)) {
    postalCodes.push(postalCode);
  }

  return postalCodes;
}

export function makeSelectPostalCodes(): Selector<
  AppStateSubset,
  Record<string, string[]>
> {
  return createSelector(makeSelectSteps(), (steps: LegacyStep[]) => {
    if (!steps) {
      return {};
    }

    const results: Record<string, string[]> = {};
    steps.forEach((step, index) => {
      if ((step.eventType as string) === STEP_CASKETING) {
        results[step.eventType] = getCasketingPostalCodes(steps, index);
        return;
      }

      if (!step?.location?.address?.postal_code) {
        return;
      }

      results[step.eventType] ||= [];
      results[step.eventType].push(step.location.address.postal_code);

      if ([STEP_INTERMENT, STEP_URN_SEALING].includes(step.eventType)) {
        results[DEAL_TYPE_MARBLE] ||= [];
        results[DEAL_TYPE_MARBLE].push(step.location.address.postal_code);
      }
    });

    return results;
  });
}
