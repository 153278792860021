import { authenticate } from '../../lib/decorators'
import { put } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'

export const Disabled = {
  update: (id: number, value: boolean): ApiRequestDescriptor<void> =>
    authenticate(
      put(`/api/v1/suppliers/warehouses/flights/${id}/disabled`, {
        value,
      }),
    ),
}
