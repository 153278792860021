export { AdministrativeProcedures } from './AdministrativeProcedures'
export { Autocompletes } from './Autocompletes'
export { Auth } from './Auth'
export { Cleaning } from './Cleaning'
export { Client } from './Client'
export { Clients } from './Clients'
export { Cityhalls } from './Cityhalls'
export { Companies } from './Companies'
export { ConcessionSemelleTypes } from './ConcessionSemelleTypes'
export { ConcessionTypes } from './ConcessionTypes'
export { Consulates } from './Consulates'
export { Crematoriums } from './Crematoriums'
export { Deals } from './Deals'
export { DocumentEditions } from './DocumentEditions'
export { DeathDeclarations } from './DeathDeclarations'
export { Defuncts } from './Defuncts'
export { DocumentTypes } from './DocumentTypes'
export { EditableDocuments } from './EditableDocuments'
export { Fintecture } from './Fintecture'
export { FlowerFunnel } from './FlowerFunnel'
export { FuneralFunnel } from './FuneralFunnel'
export { FuneralParlors } from './FuneralParlors'
export { Google } from './Google'
export { Leads } from './Leads'
export { Entities } from './Entities'
export { EntityInvoices } from './EntityInvoices'
export { Flights } from './Flights'
export { FundingAgencies } from './FundingAgencies'
export { FuneralBrands } from './FuneralBrands'
export { Graveyards } from './Graveyards'
export { Hospitals } from './Hospitals'
export { MarbleFunnel } from './MarbleFunnel'
export { Notifications } from './Notifications'
export { OngoingFormalities } from './OngoingFormalities'
export { Payments } from './Payments'
export { Police } from './Police'
export { Prefectures } from './Prefectures'
export { Prestations } from './Prestations'
export { Pro } from './Pro'
export { Products } from './Products'
export { Rha } from './Rha'
export { Signatures } from './Signatures'
export { Suppliers } from './Suppliers'
export { SupportingDocuments } from './SupportingDocuments'
export { Upload } from './Upload'
export { UploadedDocumentSms } from './UploadedDocumentSms'
export { Users } from './Users'
export { Website } from './Website'
export { Worships } from './Worships'
export { WorshipTypes } from './WorshipTypes'

export function absolute(path: string): string {
  const root = process.env.API_ENDPOINT || ''
  return `${root}${path}`
}
