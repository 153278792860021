type ParamScalarValue = string | number
type ParamArrayValue = ParamScalarValue | ParamScalarValue[]
type ParamValue = ParamArrayValue | Record<string, ParamArrayValue>

function isObject(value: unknown): value is Record<string, ParamArrayValue> {
  return !Array.isArray(value) && typeof value === 'object' && value !== null
}

function appendValue(searchParams: URLSearchParams, key: string, value: ParamValue): void {
  if (isObject(value)) {
    Object.entries(value).forEach(([subkey, subvalue]) => {
      appendValue(searchParams, `${key}[${subkey}]`, subvalue)
    })
  } else if (Array.isArray(value)) {
    value.forEach(paramValue => searchParams.append(`${key}[]`, paramValue.toString()))
  } else {
    searchParams.append(key, value.toString())
  }
}

export function create(params: Record<string, ParamValue>): URLSearchParams {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([param, values]) => {
    appendValue(searchParams, param, values)
  })

  return searchParams
}
